import React, { useEffect } from "react";
import Layout from "../components/_App/layout";
import SEO from "../components/_App/seo";
import Navbar from "../components/_App/NavbarStyleFour";
import img from "../images/ballstaffing.png";
import Footer from "../components/_App/Footer";

const Outsourcing = () => {
    const [isMobile, setMobile] = React.useState(false);

    const handleResize = () => {
        if (window.innerWidth < 767) {
            setMobile(true);
        } else {
            setMobile(false);
        }
    };

    useEffect(() => {
        window.addEventListener("resize", () => handleResize());
        handleResize();
        return window.removeEventListener("resize", () => handleResize());
    }, []);

    return (
        <Layout>
            <SEO title="Outsourcing | APPIUS - Software House & Web Agency" />
            <Navbar />
            <div
                style={{
                    height: "100vh",
                    width: "100%",
                    overflow: "hidden",
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                }}
                className="bg-staffing"
            >
                <div className="ballStaffing" />
                <img className="ballStaffing-2" src={img} alt="" />
            </div>
            <div
                style={{ height: "100%", width: "100%", overflow: "hidden" }}
                className="bg-staffing-dark position-relative"
            >
                <div className={`container h-50${isMobile ? "" : " mt-5"}`}>
                    <div className="row text-outsourcing">
                        <div className={`col-lg-12 ${isMobile ? "" : " pt-5"}`}>
                            <p className="BrinnanLight text-dark">
                                Siamo amanti del lavoro fatto a regola d'arte,
                                innamorati della dedizione dei professionisti
                                che ogni giorno aiutano imprese di ogni
                                dimensione a dar forma a progetti che una volta
                                erano solo sogni.
                            </p>
                            <p className="BrinnanLight text-dark">
                                Per questo mettiamo tutto il nostro impegno nel
                                fornirti soluzioni utili a superare le sfide del
                                mercato di oggi.
                            </p>
                            <p className="BrinnanLight text-dark">
                                <span
                                    style={{
                                        color: "rgb(231 130 0)",
                                        fontWeight: "bold",
                                    }}
                                >
                                    L'outsourcing di Appius
                                </span>{" "}
                                &eacute; lo strumento che ti aiuta a trovare gli
                                sviluppatori di cui ha bisogno il tuo progetto e
                                di ingaggiarli per il tempo necessario al suo
                                completamento.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row margintop">
                        <div className="col-12 scroll-mobile">
                            <div className="row scroll-mobile">
                                <div className="col-4">
                                    <div className="specchietto">
                                        <p
                                            className="BrinnanLight"
                                            style={{
                                                color: "rgb(231 130 0)",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            Risparmio:
                                        </p>
                                        <p className="BrinnanLight mt-3 text-dark">
                                            Affidandoci lo sviluppo risparmi
                                            tempo e denaro che altrimenti
                                            spenderesti nella gestione interna
                                            di queste attivit&agrave;.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="specchietto">
                                        <p
                                            className="BrinnanLight"
                                            style={{
                                                color: "rgb(231 130 0)",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            Accesso a competenze specifiche:
                                        </p>
                                        <p className="BrinnanLight text-dark">
                                            Hai accesso a competenze e
                                            tecnologie avanzate, difficilmente
                                            reperibili.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="specchietto">
                                        <p
                                            className="BrinnanLight"
                                            style={{
                                                color: "rgb(231 130 0)",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            Maggiore flessibilit&agrave;:
                                        </p>
                                        <p className="BrinnanLight mt-3 text-dark">
                                            Adatti rapidamente i servizi
                                            informatici alle esigenze del
                                            business, senza la necessit&agrave;
                                            di investire in nuove risorse
                                            interne.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </Layout>
    );
};

export default Outsourcing;
